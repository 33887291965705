import React, { useState, useEffect } from "react";
import MainLogo from "../../../images/Wordmark-Logo-Blue.svg";

const greenwaveIndex = () => {
  //create CSV file data in an array
  var csvFileRowData = [
    [
      "NEW",
      "",
      "355576830",
      "",
      "",
      "",
      "Darnell Davis",
      "2712 mortin ct.",
      "",
      "Bakersfield",
      "CA",
      "93304",
      "Residential",
      "Darnell Davis",
      "",
      "6614489859",
      "",
      "",
      "7/31/2022",
      "7/31/2022",
      "7/31/2023",
      "Winston Robertson",
      "113GWEPGE_VAR_WACOG_REC_SM",
      "PG&E",
      "NAVC_GREENWAVEPGE",
      "E",
    ],
  ];

  //create a user-defined function to download CSV file
  const csv_download = () => {
    //define the heading for each row of the data
    var csv =
      "import_type,cipher_number,account_number,meter_number,licensee_account_number,company_name,facility_name,facility_address,facility_unit,facility_city,facility_state,facility_zip,service_level,family_contact_name,family_contact_title,facility_phone,facility_fax,facility_email,SALE_DATE,deal_date_start,deal_date_end,sales_agent_user_code,sales_agent_user_code,utility,sales_group_code,Language\n";

    //merge the data with CSV
    csvFileRowData.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);

    //provide the name for the CSV file to be downloaded
    hiddenElement.download =
      "2023_01_09-Gas_Imports_GREENWAVE_PGE_Insert-1.csv";
    hiddenElement.click();
  };
  return (
    <div class="min-h-full">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="relative flex h-16 items-center justify-between border-b border-gray-200">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <a href="/">
                <img
                  class="h-12 w-auto hover:opacity-70"
                  src={MainLogo}
                  alt="Your Company"
                />
              </a>
            </div>

            <div class="hidden lg:ml-10 lg:block">
              <div class="flex space-x-4">
                <a
                  href="#"
                  class="px-3 py-2 rounded-md text-sm font-medium text-gray-900 hover:bg-gray-100"
                  aria-current="page"
                >
                  Contact Supplier Admin
                </a>
                <a
                  href="#"
                  class="px-3 py-2 rounded-md text-sm font-medium text-gray-900 hover:bg-gray-100"
                  aria-current="page"
                >
                  Contact Opengate Admin
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
            <div class="w-full max-w-lg lg:max-w-xs">
              <label for="search" class="sr-only">
                Search Report #/Name
              </label>
              <div class="relative text-gray-400 focus-within:text-gray-500">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search"
                  class="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-sky-500 focus:placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                  placeholder=" Search Report #/Name"
                  type="search"
                  name="search"
                />
              </div>
            </div>
          </div>
          <div class="flex lg:hidden">
            <button
              type="button"
              class="inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span class="sr-only">Open main menu</span>

              <svg
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>

              <svg
                class="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="hidden lg:ml-4 lg:block">
            <div class="flex items-center">
              <div class="relative ml-3 flex-shrink-0">
                <div>
                  <img
                    class="h-10 w-auto saturate-0"
                    src="https://greenwaveenergy.com/wp-content/uploads/2022/06/cropped-greenwave-logo-2022-bold-2048x683.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header class="bg-gray-50 py-8">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
          <div class="min-w-0 flex-1">
            <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Greenwave Energy
            </h1>
            <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <svg
                  class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                    clip-rule="evenodd"
                  />
                </svg>
                Opengate Supplier Since January 9, 2020
              </div>
            </div>
          </div>
          <div class="mt-5 flex xl:mt-0 xl:ml-4">
            <span class="hidden sm:block">
              <button
                type="button"
                class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                <svg
                  class="-ml-1 mr-2 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
                </svg>
                Edit Report Template
              </button>
            </span>

            <span class="ml-3 hidden sm:block">
              <button
                type="button"
                class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="-ml-1 mr-2 h-5 w-5 text-gray-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                View Current Template
              </button>
            </span>

            <div class="relative ml-3 sm:hidden">
              <button
                type="button"
                class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                id="mobile-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                More
                <svg
                  class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <div
                class="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="mobile-menu-button"
                tabindex="-1"
              >
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabindex="-1"
                  id="mobile-menu-item-0"
                >
                  New Template Request
                </a>
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabindex="-1"
                  id="mobile-menu-item-1"
                >
                  View
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main class="pt-8 pb-16">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="px-4 sm:px-0">
            <h2 class="text-lg font-medium text-gray-900">
              TPV Report Downloads By Vendor
            </h2>

            <div class="sm:hidden">
              <label for="tabs" class="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                class="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
              >
                <option>Applied</option>

                <option>Phone Screening</option>

                <option selected>Interview</option>

                <option>Offer</option>

                <option>Disqualified</option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="border-b border-gray-200">
                <nav class="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                  <a
                    href="#"
                    class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  >
                    All In Distribution
                    <span class="bg-gray-100 text-gray-900 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                      4
                    </span>
                  </a>

                  <a
                    href="#"
                    class="border-sky-500 text-sky-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  >
                    North American Venture Capital, LLC
                    <span class="bg-sky-100 text-sky-600 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                      6
                    </span>
                  </a>
                </nav>
              </div>
            </div>
          </div>

          <ul
            role="list"
            class="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
          >
            <li>
              <button
                onClick={() => {
                  csv_download();
                }}
                class="w-full group block hover:opacity-80"
              >
                <div class="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                  <div class="flex min-w-0 flex-1 items-center">
                    <div class="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-10 h-10 text-purple-800 group-hover:opacity-70"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z"
                          clip-rule="evenodd"
                        />
                        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                      </svg>
                    </div>
                    <div class="w-full flex-1 px-4">
                      <div>
                        <p class="text-left text-lg font-medium text-sky-600">
                          2023_01_09-Gass_Imports_GREENWAVE_PGE_Insert-1
                        </p>
                        <p class="mt-4 flex items-center text-sm text-gray-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>

                          <span class="truncate">
                            Generated Mon, 07 Feb 2023 12:57:27 GMT
                          </span>
                        </p>
                        <div class="mt-4 hidden md:block">
                          <div>
                            <p class="flex items-start text-sm text-gray-500">
                              <svg
                                class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              24 Completed TPVs
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center group-hover:opacity-70">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="mr-2 w-6 h-6 text-purple-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                      />
                    </svg>
                    <p class="text-sm"> Download Report</p>
                  </div>
                </div>
              </button>
            </li>
          </ul>

          <nav
            class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
            aria-label="Pagination"
          >
            <div class="-mt-px flex w-0 flex-1">
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
              >
                <svg
                  class="mr-3 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                    clip-rule="evenodd"
                  />
                </svg>
                Previous
              </a>
            </div>
            <div class="hidden md:-mt-px md:flex">
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
              >
                1
              </a>
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600"
                aria-current="page"
              >
                2
              </a>
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
              >
                3
              </a>
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
              >
                4
              </a>
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
              >
                5
              </a>
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
              >
                6
              </a>
            </div>
            <div class="-mt-px flex w-0 flex-1 justify-end">
              <a
                href="#"
                class="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
              >
                Next
                <svg
                  class="ml-3 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </nav>
        </div>
      </main>
    </div>
  );
};

export default greenwaveIndex;
